import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
          
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header" href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                   {/*<li>
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/tan_protect/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/tan_protect/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2020-tan-protect.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/2020-tan-protect-mobile.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN TAN &amp; PROTECT<sup>®</sup>
                    </h1>
                    <div className="products-page__intro__description">
                      <p>
                        <strong>Realza el bronceado natural</strong>
                        <br />
                        Tanto si va a exponerse un poco al sol durante una
                        escapada urbana de fin de semana como si planea pasar en
                        la playa unas largas y relajantes vacaciones, los
                        Aceites, Lociones y Sprays PIZ BUIN TAN &amp; PROTECT
                        <sup>®</sup> le ayudarán a aprovechar al máximo el
                        tiempo que esté al sol. PIZ BUIN TAN &amp; PROTECT
                        <sup>®</sup> combina una protección UVA/UVB efectiva con
                        Illumitone<sup>™</sup>. Al aumentar hasta un 70%* la
                        producción del pigmento bronceador de la piel, está
                        demostrado que Illumitone<sup>™</sup> acelera la
                        velocidad del proceso de bronceado natural**, para
                        obtener un bronceado más rápido y bonito de manera
                        natural.
                        <br />
                        <br />
                        <span className="footNote">
                          *Sprays y Lociones, pruebas in vitro **Pruebas in
                          vitro
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="aceite-en-spray-acelerador-del-bronceado" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-tan-protect-tan-intensifying-sun-oil-spray-30spf-150ml.png" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>ACEITE EN SPRAY ACELERADOR DEL BRONCEADO</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICIOS</h4>
                        <p>
                          Nuestro Aceite en Spray Acelerador del Bronceado le
                          ayuda a conseguir un color atractivo y seguro de forma
                          rápida, para que este verano pueda aprovechar cada
                          momento bajo el sol. Los aceites bronceadores
                          tradicionales pueden ofrecer un bronceado fantástico,
                          pero proporcionan una protección escasa o nula,
                          mientras que las cremas solares convencionales con un
                          FPS alto pueden hacer que tenga que esperar demasiado
                          para conseguir el color veraniego que desea. Así que…
                          ¿cómo conseguir un bronceado natural atractivo más
                          rápido sin sacrificar la protección de su piel?
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Aceite en
                            Spray Acelerador del Bronceado es exactamente lo que
                            necesita. Nuestro aceite no graso, fácil de aplicar
                            y de rápida absorción combina una protección eficaz
                            contra los rayos UVA y UVB con Melitane, un
                            ingrediente innovador que acelera el proceso de
                            bronceado natural de la piel*. El aceite de rápida
                            absorción nutre la piel dejándola hermosamente suave
                            y con brillo.
                          </p>
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Aceite en
                            Spray Acelerador del Bronceado. Para lograr un
                            hermoso bronceado más deprisa… ¡de forma segura!
                          </p>
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Aceite en
                            Spray Acelerador del Bronceado es resistente al agua
                            y a la transpiración.
                          </p>
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Aceite en
                            Spray Acelerador del Bronceado ha sido valorado
                            favorablemente por los consumidores.
                          </p>
                          <p>
                            Pedimos a 102 consumidores su opinión sobre nuestro
                            Aceite en Spray* Acelerador del Bronceado**. Después
                            de usarlo cuatro semanas…
                          </p>
                          <p>
                            <strong className="orange">El 80%</strong> notó:
                          </p>
                          <ul>
                            <li>Un mejor bronceado natural.</li>
                          </ul>
                          <p>
                            <strong className="orange">El 84%</strong> notó:
                          </p>
                          <ul>
                            <li>Un color más hermoso y con brillo.</li>
                          </ul>
                          <p>
                            <strong className="orange">El 97%</strong> notó:
                          </p>
                          <ul>
                            <li>Una protección solar efectiva.</li>
                          </ul>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Lee más
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>Disponible en</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>APLICACIÓN</h4>
                        <p>
                          Aplicar de forma generosa y uniforme antes de la
                          exposición solar. Utilizar una cantidad insuficiente
                          reduce significativamente el nivel de protección.
                          Reaplicar con frecuencia, especialmente después de
                          transpirar, nadar o secarse con una toalla. Evitar el
                          sol de mediodía y no permanecer mucho tiempo al sol,
                          incluso cuando se use protección solar. Mantener
                          alejados a los bebés y a los niños de la luz directa
                          del sol.
                        </p>
                        <p className="footNote">*Pruebas in vitro</p>
                        <p className="footNote">
                          &nbsp;**Resultados de autoevaluación obtenidos con el Spray
                          de FPS 30, 102 personas, 4 semanas, al menos una
                          aplicación al día
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES Y TECNOLOGÍA{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                EL AVANZADO SISTEMA DE FILTROS SOLARES UVA/UVB
                                DE PIZ BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas en cuidado solar usamos
                                    tecnologías innovadoras para asegurarnos de
                                    que, siempre que tome el sol, consigue el
                                    equilibrio perfecto entre bronceado y
                                    protección.
                                  </p>
                                  <p>
                                    Cada avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene una
                                    innovadora tecnología de protección solar
                                    desarrollada por Kenvue.
                                    Ofrece protección UVA/UVB fotoestable de
                                    amplio espectro y contribuye a que el
                                    protector solar de PIZ BUIN<sup>®</sup>{" "}
                                    cumpla con la normativa europea más
                                    reciente.
                                  </p>
                                  <p>
                                    Todos los avanzados sistemas de filtros
                                    solares UVA/UVB de PIZ BUIN<sup>®</sup>{" "}
                                    ayudan a proteger la piel de la radiación
                                    UVA y UVB.
                                  </p>
                                  <p>
                                    Los rayos UVB, "los rayos que queman", dañan
                                    la superficie de la piel y son la principal
                                    causa de quemaduras solares.
                                  </p>
                                  <p>
                                    Los rayos UVA , "los rayos que envejecen",
                                    penetran más profundamente y dañan el
                                    colágeno y la elastina, provocando un
                                    envejecimiento prematuro de la piel y
                                    cáncer.
                                  </p>
                                  <p>
                                    El avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene
                                    tanto filtros UVA como UVB para proteger la
                                    piel del sol y de los daños a largo plazo, a
                                    la vez que protegen de los graves efectos de
                                    la radiación UVA. Al cubrir la mayor parte
                                    del espectro UVA/UVB, los filtros ofrecen
                                    una amplia protección.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMINA E
                                <div className="textBlock bottom">
                                  <p>
                                    La Vitamina E* es un potente antioxidante
                                    natural que ayuda a proteger de los
                                    radicales libres que dañan las células de la
                                    piel y provocan su envejecimiento prematuro.
                                  </p>
                                  <p>*In vitro </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    Está demostrado que Illumitone™ mejora el
                                    proceso natural del bronceado, aumentando la
                                    producción del pigmento de bronceado natural
                                    de la piel (melanina) hasta un 70%*,
                                    Illumitone™ no mancha la ropa, los bañadores
                                    ni las toallas.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Sprays y lociones probados in vitro
                                    </span>
                                    .
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="loci-n-solar-intensificadora-del-bronceado" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/eff51ba86541ed918048fedf6e5e85ee_f28.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>LOCIÓN SOLAR INTENSIFICADORA DEL BRONCEADO</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICIOS</h4>
                        <p>
                          La fórmula no grasa y no pegajosa de PIZ BUIN TAN
                          &amp; PROTECT<sup>®</sup> Loción Solar Intensificadora
                          del Bronceado hidrata intensamente su piel para
                          prevenir la descamación y contribuye a un bronceado
                          más duradero.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Loción Solar
                            Intensificadora del Bronceado es resistente al agua
                            y a la transpiración.
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Lee más
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>Disponible en</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>APLICACIÓN</h4>
                        <p>
                          Aplicar de forma generosa y uniforme antes de la
                          exposición solar. Utilizar una cantidad insuficiente
                          reduce significativamente el nivel de protección.
                          Reaplicar con frecuencia, especialmente después de
                          transpirar, nadar o secarse con una toalla. Evitar el
                          sol de mediodía y no permanecer mucho tiempo al sol,
                          incluso cuando se use protección solar. Mantener
                          alejados a los bebés y a los niños de la luz directa
                          del sol.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES Y TECNOLOGÍA{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                EL AVANZADO SISTEMA DE FILTROS SOLARES UVA/UVB
                                DE PIZ BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas en cuidado solar usamos
                                    tecnologías innovadoras para asegurarnos de
                                    que, siempre que tome el sol, consigue el
                                    equilibrio perfecto entre bronceado y
                                    protección.
                                  </p>
                                  <p>
                                    Cada avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene una
                                    innovadora tecnología de protección solar
                                    desarrollada por Kenvue.
                                    Ofrece protección UVA/UVB fotoestable de
                                    amplio espectro y contribuye a que el
                                    protector solar de PIZ BUIN<sup>®</sup>{" "}
                                    cumpla con la normativa europea más
                                    reciente.
                                  </p>
                                  <p>
                                    Todos los avanzados sistemas de filtros
                                    solares UVA/UVB de PIZ BUIN<sup>®</sup>{" "}
                                    ayudan a proteger la piel de la radiación
                                    UVA y UVB.
                                  </p>
                                  <p>
                                    Los rayos UVB, "los rayos que queman", dañan
                                    la superficie de la piel y son la principal
                                    causa de quemaduras solares.
                                  </p>
                                  <p>
                                    Los rayos UVA , "los rayos que envejecen",
                                    penetran más profundamente y dañan el
                                    colágeno y la elastina, provocando un
                                    envejecimiento prematuro de la piel y
                                    cáncer.
                                  </p>
                                  <p>
                                    El avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene
                                    tanto filtros UVA como UVB para proteger la
                                    piel del sol y de los daños a largo plazo, a
                                    la vez que protegen de los graves efectos de
                                    la radiación UVA. Al cubrir la mayor parte
                                    del espectro UVA/UVB, los filtros ofrecen
                                    una amplia protección.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMINA E
                                <div className="textBlock bottom">
                                  <p>
                                    La Vitamina E* es un potente antioxidante
                                    natural que ayuda a proteger de los
                                    radicales libres que dañan las células de la
                                    piel y provocan su envejecimiento prematuro.
                                  </p>
                                  <p>*In vitro </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    Está demostrado que Illumitone™ mejora el
                                    proceso natural del bronceado, aumentando la
                                    producción del pigmento de bronceado natural
                                    de la piel (melanina) hasta un 70%*,
                                    Illumitone™ no mancha la ropa, los bañadores
                                    ni las toallas.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Sprays y lociones probados in vitro
                                    </span>
                                    .
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="spray-solar-intensificador-del-bronceado" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-tan-protect-tan-intensifying-sun-spray-30spf-150ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SPRAY SOLAR INTENSIFICADOR DEL BRONCEADO</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFICIOS</h4>
                        <p>
                          Nuestro Spray Solar Intensificador del Bronceado
                          combina una protección UVA/UVB efectiva con
                          IllumitoneTM.{" "}
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            Hidrata la piel, lo cual ayuda a prevenir la
                            descamación y contribuye a alargar el bronceado.
                          </p>
                          <p>
                            El resultado es una piel bronceada y con un tono
                            natural.
                          </p>
                          <p>
                            Su fórmula de absorción inmediata es resistente al
                            agua y al sudor{" "}
                          </p>
                          <p> </p>
                          <p>
                            PIZ BUIN® TAN &amp; PROTECT Spray Solar
                            Intensificador del bronceado presenta un formato
                            innovador que proporciona protección solar inmediata
                            y de forma efectiva.
                          </p>
                          <p>
                            Gracias a su posición múltiple, conseguirás
                            aplicarlo de una forma más cómoda y, en cualquier
                            lugar
                          </p>
                          <div> </div>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Lee más
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>Disponible en</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>APLICACIÓN</h4>
                        <p>
                          Aplicar generosamente y re-aplicar con frecuencia. Una
                          cantidad insuficiente reduce el nivel de protección.
                          Evitar el sol del medio día y exposiciones prolongadas
                          al sol. Mantener a los bebés y a los niños alejados de
                          la luz solar directa. Para uso facial, pulverizar en
                          las manos y aplicar. Usar con precaución ya que el
                          suelo puede volverse resbaladizo durante su uso.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES Y TECNOLOGÍA{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                EL AVANZADO SISTEMA DE FILTROS SOLARES UVA/UVB
                                DE PIZ BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas en cuidado solar usamos
                                    tecnologías innovadoras para asegurarnos de
                                    que, siempre que tome el sol, consigue el
                                    equilibrio perfecto entre bronceado y
                                    protección.
                                  </p>
                                  <p>
                                    Cada avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene una
                                    innovadora tecnología de protección solar
                                    desarrollada por Kenvue.
                                    Ofrece protección UVA/UVB fotoestable de
                                    amplio espectro y contribuye a que el
                                    protector solar de PIZ BUIN<sup>®</sup>{" "}
                                    cumpla con la normativa europea más
                                    reciente.
                                  </p>
                                  <p>
                                    Todos los avanzados sistemas de filtros
                                    solares UVA/UVB de PIZ BUIN<sup>®</sup>{" "}
                                    ayudan a proteger la piel de la radiación
                                    UVA y UVB.
                                  </p>
                                  <p>
                                    Los rayos UVB, "los rayos que queman", dañan
                                    la superficie de la piel y son la principal
                                    causa de quemaduras solares.
                                  </p>
                                  <p>
                                    Los rayos UVA , "los rayos que envejecen",
                                    penetran más profundamente y dañan el
                                    colágeno y la elastina, provocando un
                                    envejecimiento prematuro de la piel y
                                    cáncer.
                                  </p>
                                  <p>
                                    El avanzado sistema de filtros solares
                                    UVA/UVB de PIZ BUIN<sup>®</sup> contiene
                                    tanto filtros UVA como UVB para proteger la
                                    piel del sol y de los daños a largo plazo, a
                                    la vez que protegen de los graves efectos de
                                    la radiación UVA. Al cubrir la mayor parte
                                    del espectro UVA/UVB, los filtros ofrecen
                                    una amplia protección.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMINA E
                                <div className="textBlock bottom">
                                  <p>
                                    La Vitamina E* es un potente antioxidante
                                    natural que ayuda a proteger de los
                                    radicales libres que dañan las células de la
                                    piel y provocan su envejecimiento prematuro.
                                  </p>
                                  <p>*In vitro </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    Está demostrado que Illumitone™ mejora el
                                    proceso natural del bronceado, aumentando la
                                    producción del pigmento de bronceado natural
                                    de la piel (melanina) hasta un 70%*,
                                    Illumitone™ no mancha la ropa, los bañadores
                                    ni las toallas.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Sprays y lociones probados in vitro
                                    </span>
                                    .
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/es/nuestros-productos/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/es/nuestros-productos/active-protect/"
                      target="_self"
                    >
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="/es/nuestros-productos/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/es/nuestros-productos/tan-protect/"
                      target="_self"
                    >
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/es/nuestros-productos/instantglow/"
                      target="_self"
                    >
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/es/nuestros-productos/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/after-sun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/es/nuestros-productos/galeria/" target="_self">
                      Galeria
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Lee más');\n} else {\nbox.slideDown();\n$(this).text('Leer menos');\n}\n});\n});\n"
          }}
        />
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
